import React from 'react';

const Banner = () => {
  return (
    <section className="banner" id="home">
      <div className="textBox">
         <h2> Hello, my name is <br/><span> Gustavo A. Fernandes, </span></h2>
         <h3> 
         a <span> Software Engineer </span>. I can truly say that I <span> love </span>
         software development. It's kind of a lifestyle. Nowadays I'm working as
         a <span> Data Scientist </span>, also a job that I have such fun, and I can
         say more. <span> Working </span> as Data Scientist allows me to use a bunch of
         principles from software engineering, machine learning, statistics,
         mathematics, optimization, artificial intelligence, data structures, 
         etc, all those subjects that I've learned during my PhD and from my past jobs.
         Oh, and I <span> teach </span> Computer Science at an University in my city.
         </h3>
         <a href="http://www.linkedin.com/in/gustavoafernandes" class="btn"> About Me! </a>
      </div>
    </section>
  );
}

export default Banner;
