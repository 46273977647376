import React from 'react';

const Projects = () => {
  return (
    <section className="projects" id="work">
       <div class="heading white">
         <h2>Some Projects</h2>
      </div>
      <div class="content">
         <div class="projectsBox">
            <img src="images/icon2.png"/>
            <h2>Sample Projects</h2> 
            <p><a href="https://github.com/gustavoafernandes">Some example apps</a> that I usually share with my students.</p>
         </div> 
         <div class="projectsBox">
            <img src="images/icon5.png"/>
            <h2>Research</h2> 
            <p>You can see <a href="http://lattes.cnpq.br/5297454846841638">here the research projects</a> that I've developed together with other researchers during my doctor's degree.
            </p>
         </div> 
      </div>
    </section>
  );
}

export default Projects;
