import React from 'react';

const Contact = () => {
  return (
    <section className="contact" id="contact">
       <div class="heading white">
         <h2>Contact me!</h2>
         <p>Feel free to contact me.</p>
      </div>
      <div class="content">
         <div class="contactInfo">
            <div class="contactInfoBox">
               <div class="box">
                  <div class="icon">
                     <i class="fas fa-envelope"></i>
                  </div>
                  <div class="text">
                     <h3>Email Address:</h3>
                     <p>gustavo.alfer@gmail.com</p>
                  </div>
               </div> 
               <div class="box">
                  <div class="icon">
                     <i class="fas fa-at"></i>
                  </div>
                  <div class="text">
                     <h3>Find me at the social networks:</h3>
                     <p>linkedin.com/in/gustavoafernandes</p>
                  </div>
               </div> 
            </div>
         </div>
      </div>
    </section>
  );
}

export default Contact;
