import React from 'react';

const Header = () => {
  const toggleMenu = () => {
    const menuToggle = document.querySelector('.toggle');
    const menu = document.querySelector('.menu');
    menuToggle.classList.toggle('active');
    menu.classList.toggle('active');
  };

  return (
    <header>
      <a href="#" className="logo">Personal Page</a>
      <div className="toggle" onClick={toggleMenu}></div>
      <ul className="menu">
        <li><a href="#home">Home</a></li>
        <li><a href="#work">Some Projects</a></li>
        <li><a href="http://lattes.cnpq.br/5297454846841638">Some Research</a></li>
        <li><a href="#contact">Contact me</a></li>
      </ul>
    </header>
  );
}

export default Header;
