import React, { useEffect } from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import Projects from './components/Projects';
import Contact from './components/Contact';
import './App.css';

function App() {
  useEffect(() => {
    window.addEventListener('scroll', function() {
      const header = document.querySelector('header');
      header.classList.toggle('sticky', window.scrollY > 0);
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <Banner />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
